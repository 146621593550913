import { ModalFacelift } from "@rocketlanguages/ui";
import { useStoreDispatch, useStoreSelector } from "../../../store";

import API from "@rocketlanguages/shared/res/Api";
import { Link, useLocation } from "react-router-dom";
import classes from "./BadgeNotification.module.scss";
import { clsx } from "clsx";
import { getRandomCongratulationsMessage } from "@rocketlanguages/shared/utils";
import { RateableTestLocaleCodeArray } from "@rocketlanguages/shared/utils/constants";
import { removeRankUpgradeNotification } from "@rocketlanguages/shared/store/user/actions";
import { useCallback } from "react";
import usePromise from "@rocketlanguages/shared/hooks/usePromise";
import useTranslation from "@rocketlanguages/shared/hooks/useTranslation";

export default function BadgeNotification() {
  const dispatch = useStoreDispatch();
  const newRank = useStoreSelector((store) => store.user.stats.newRank);
  const activeCourse = useStoreSelector((store) => store.preferences.activeCourse);
  const activeProduct = useStoreSelector((store) => store.preferences.activeProduct);
  const location = useLocation();

  const getOwnReviewPromise = useCallback(() => {
    return API.getJson(["v2/reviews/my-review/course/{course}", { course: activeCourse?.id || 0 }]);
  }, [activeCourse]);

  const { state } = usePromise(getOwnReviewPromise);

  const isInTest = RateableTestLocaleCodeArray.some((testName) => location.hash.startsWith(`#${testName}`));

  if (!newRank || state?.status === "loading" || isInTest) {
    return null;
  }

  const removeUpgradeNotification = () => dispatch(removeRankUpgradeNotification());

  return (
    <BadgeNotificationView
      onClose={removeUpgradeNotification}
      // 404 error not getting detected here, so if state is empty (no review passed back), treat it as an error
      promptReview={state?.status === "error" || !state?.data}
      productId={activeProduct?.id || 1}
      courseSlug={activeCourse?.slug || ""}
      newRank={newRank}
    />
  );
}

export type BadgeNotificationViewProps = {
  productId: number;
  courseSlug: string;
  onClose(): void;
  promptReview: boolean;
  newRank: {
    rankName: string;
    badgeUrl: string;
  };
};

export function BadgeNotificationView({
  courseSlug,
  productId,
  onClose,
  promptReview,
  newRank,
}: BadgeNotificationViewProps) {
  const t = useTranslation();
  return (
    <ModalFacelift
      isOpen
      onClose={onClose}
      onEscapeKeyDown={onClose}
      onPointerDownOutside={onClose}
      className={clsx(classes.notificationContainer)}
    >
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-center text-3xl">{getRandomCongratulationsMessage(courseSlug)}</h1>
        <p>{t("achieved-new-badge")}</p>
      </div>
      <div className="mx-auto flex min-h-44 min-w-44 items-center justify-center p-6">
        <img src={newRank?.badgeUrl} alt="" />
      </div>
      <h2 className="text-center">{newRank?.rankName}</h2>
      {promptReview && (
        <div className="flex flex-col items-center justify-center gap-y-2 pt-8">
          <h2>{t("loving-the-course")}</h2>

          <Link
            to={`/members/products/${productId}/reviews/write`}
            onClick={onClose}
            className="flex h-11 items-center justify-center rounded-3xl bg-missileaccent px-12 text-sm font-bold text-white transition-all duration-100 hover:bg-missileaccent/90"
          >
            {t("write-a-review")}
          </Link>

          <button type="button" className="text-sm underline" onClick={onClose}>
            Dismiss
          </button>
        </div>
      )}
    </ModalFacelift>
  );
}
